<template>
  <BaseLayout>

    <section class="ol-dashboard ol-product-detail">
      <div class="ol-wrapper ol-wrapper--large">
        <div class="ol-dashboard--wrapper">
          <aside class="ol-dashboard__sidebar ol-dashboard__sidebar--mobile">
            
            <h4 class="ol-dashboard__sidebar-title">Menu</h4>

            <nav class="ol-dashboard__sidebar-nav">
              <router-link to="/profile" class="ol-dashboard__sidebar-nav-link profile-menu">
                <i class="ol-fa fas fa-user"></i> Informasi Akun
              </router-link>
              <router-link to="/profile/wishlist" class="ol-dashboard__sidebar-nav-link profile-menu">
                  <i class="ol-fa fas fa-heart"></i> Wishlist
              </router-link>
              <router-link to="/profile/orders" class="ol-dashboard__sidebar-nav-link profile-menu">
                <i class="ol-fa fas fa-shopping-bag"></i> Pesanan
              </router-link>
              <router-link to="/profile/event" class="ol-dashboard__sidebar-nav-link profile-menu">
                <i class="ol-fa fas fa-gift"></i> Event
              </router-link>
              <router-link to="/profile/address" class="ol-dashboard__sidebar-nav-link profile-menu" >
                <i class="ol-fa fas fa-map-marker-alt"></i> Alamat
              </router-link>
              <router-link to="/profile/dropshipper" class="ol-dashboard__sidebar-nav-link profile-menu" >
                <i class="ol-fa fas fa-user"></i> Dropshipper
              </router-link>
              <router-link to="/profile/banks" class="ol-dashboard__sidebar-nav-link profile-menu ">
                <i class="ol-fa fas fa-credit-card"></i> Daftar Rekening
              </router-link>
              <router-link to="/profile/notifications" class="ol-dashboard__sidebar-nav-link profile-menu">
                <i class="ol-fa fas fa-bell"></i> Notifikasi
              </router-link>
              <!-- <router-link to="/profile/vouchers" class="ol-dashboard__sidebar-nav-link profile-menu">
                <i class="ol-fa fas fa-ticket-alt"></i> Voucher
              </router-link> -->
              <router-link to="/profile/password" class="ol-dashboard__sidebar-nav-link profile-menu">
                <i class="ol-fa fas fa-lock"></i> Ubah Kata Sandi
              </router-link>
              <a href="javascript:void(0);" @click="logout()" class="ol-dashboard__sidebar-nav-link profile-menu">
                <i class="ol-fa fas fa-arrow-circle-right"></i> Keluar
              </a>
            </nav>
          </aside>
          <!-- <div class="ol-dashboard__container">
            <router-view></router-view>
          </div> -->
        </div>
      </div>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/components/landing/BaseLayout.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PlainMenu",
  components:{
    BaseLayout,
  },
  computed: {
		...mapGetters("auth", ["authenticated"]),
  },
  methods:{
		...mapActions("auth", ["logout"]),

    jqScrolling2top(){
          //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
      },
  },
  async mounted(){
      this.jqScrolling2top();
  },
  watch: {
		authenticated: function(value) {
			if (!value) this.$router.replace("/");
		},
  }
};
</script>

<style></style>
